/*----------------------------------------*/
/*  02. Header
/*----------------------------------------*/
.tp-header-menu-area.header-sticky.sticky-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  background: $white;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  @include transition(0.3s);
}
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.side-menu-icon i .bar_icon {
  color: #102579;
  font-size: 32px;
  cursor: pointer;
}
.tp-sticky-column {
  display: none;
  @media #{$lg,$md,$xs} {
    display: inherit;
  }
}
.tp-sticky-column-btn {
  display: none;
}
.sticky-menu {
  & .tp-sticky-column {
    display: inherit;
  }
  & .tp-sticky-column-btn {
    display: inherit;
    @media #{$lg,$md,$xs} {
      display: none;
    }
    & .theme-btn {
      @media #{$xl} {
        padding: 0px 25px;
      }
    }
  }
}
.tp-sticky-column-btn {
  @media #{$lg} {
    display: none;
  }
}
.tp-sticky-btn {
  margin-left: auto;
  line-height: 1;
}
.tp-transparent-header-menu {
  position: absolute;
  top: 105px;
  left: 0px;
  right: 0px;
  z-index: 9;
  width: 100%;
  @media #{$lg,$md,$xs} {
    position: static;
    background: $white;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
    padding: 15px 0px;
  }
}
.tp-header-top-info {
  display: flex;
  align-items: center;
}
.tp-header-top-info-single {
  &-icon {
    float: left;
    & i {
      font-size: 45px;
      color: $white;
      line-height: 0.8;
      vertical-align: middle;
    }
  }
  &-text {
    overflow: hidden;
    & a {
      color: $white;
    }
  }
  &.mr-85 {
    @media #{$xxl} {
      margin-right: 30px;
    }
    @media #{$xl} {
      margin-right: 20px;
    }
  }
  &.pr-40 {
    @media #{$xxl} {
      padding-right: 25px;
    }
    @media #{$xl} {
      padding-right: 20px;
    }
  }
  &.mr-40 {
    @media #{$xxl} {
      margin-right: 25px;
    }
    @media #{$xl} {
      margin-right: 20px;
    }
  }
}
.tp-header-top-info-single-icon-call {
  & i {
    animation: phone-icon-animate 1.5s ease-in-out infinite;
  }
}
.tp-header-top-info-single-label {
  font-size: 15px;
  color: $white;
  display: block;
}
.tp-header-top-info-single-content {
  font-size: 16px;
  color: $white;
  display: block;
}
.tp-header-top-info-single-btn {
  line-height: 1;
}
.border-right-1 {
  border-right: 1px solid #fed10c;
}

// header menu start
.tp-main-menu-bg {
  background: $white;
  margin-left: 30px;
  margin-right: 30px;
  @media #{$lg,$md,$xs} {
    margin: 0px;
  }
}
.tp-main-menu {
  & ul {
    list-style: none;
    & li {
      display: inline-block;
      margin: 0px 23px;
      position: relative;
      & a {
        font-size: 17px;
        font-weight: 500;
        color: $body-text-color;
        display: block;
        position: relative;
        z-index: 1;
        line-height: 1;
        padding: 31px 0px;
        &:before {
          position: absolute;
          content: "";
          height: 4px;
          width: 0%;
          left: 0px;
          bottom: 31px;
          background: $theme-color;
          @include transition(0.3s);
          z-index: -1;
        }
        &:hover,
        &.active {
          &::before {
            width: 100%;
          }
        }
      }
      /*submenu start*/
      & .sub-menu {
        background: $white none repeat scroll 0 0;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
        left: 0;
        opacity: 0;
        position: absolute;
        top: 119%;
        transition: all 0.3s ease 0s;
        visibility: hidden;
        width: 270px;
        z-index: 9;
        border-top: 4px solid $theme-color;
        text-align: left;
        padding: 15px 0;
        & li {
          display: block;
          margin: 0px;
          padding: 8px 25px 8px 25px;
          & a {
            padding: 0px;
            display: inline-block;
            color: #212237;
            position: relative;
            &:after {
              display: none;
            }
            &:before {
              content: "";
              width: 0;
              height: 1px;
              bottom: 0;
              position: absolute;
              left: auto;
              right: 0;
              z-index: -1;
              transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
              background: $theme-color;
            }
          }
          &:hover {
            & a {
              color: $theme-color;
              &:before {
                width: 100%;
                left: 0;
                right: auto;
              }
            }
          }
        }
      }
      /*submenu end*/
      &:hover {
        & a {
          color: $theme-color;
        }
        & .sub-menu {
          opacity: 1;
          visibility: visible;
          top: 100%;
        }
      }
    }
  }
}
//header menu end

//header sidebar start
.tp-mobile-menu.mean-container {
  clear: both;
  overflow: hidden;
}
.contact-infos {
  margin-top: 30px;
  & h4 {
    font-size: 27px;
    color: $white;
    margin-bottom: 20px;
  }
  & ul {
    & li {
      font-size: 15px;
      color: $white;
      margin-bottom: 15px;
      list-style: none;
      &:last-child {
        margin-bottom: 0px;
      }
      & i {
        color: $theme-color;
        vertical-align: middle;
        width: 25px;
        display: inline-block;
      }
    }
  }
}
.sidebar__menu--social {
  margin-top: 25px;
  & a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
    background: #393f53;
    color: $white;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 5px;
    &:before {
      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      height: 100%;
      width: 0%;
      background: $theme-color;
      z-index: -1;
      transform: rotate(90deg);
      @include transition(0.3s);
    }
    &:hover {
      &:before {
        width: 100%;
      }
      & i {
        transform: rotate(360deg);
      }
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}
//header sidebar menu

@keyframes hero_slide {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .home_hero_slide .carousel-indicators {
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    top: inherit;
    right: inherit;
    width: inherit;
    display: flex;
  }
  .home_hero_slide .carousel-indicators button.active {
    height: 6px;
    width: 6px;
    background: #06ae5a;
    transform: translateY(0px);
    margin: 8px 11px 20px 9px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-slider-video-btn button {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}
// header home one slider start

//header home two start here
.tp-header-area-two.header-sticky.sticky-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 91;
  background: none;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  @include transition(0.3s);
  background: $white;
}
.tp-header-area-two.header-sticky.sticky-menu {
  & .tp-header-top-two {
    display: none;
  }
  & .tp-header-logo-two-inner {
    min-height: 80px;
    padding-left: 20px;
    @media #{$xl,$xxl} {
      padding: 8px 15px;
      min-height: inherit;
    }
  }
}
.tp-header-area-two {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  right: 0px;
  z-index: 91;
  @media #{$lg,$md,$xs} {
    background: $white;
  }
}
.tp-header-logo-two {
  margin-right: 35px;
  @media #{$lg,$md,$xs} {
    margin-right: 0px;
    max-width: 240px;
  }
}
.tp-header-menu-two-wrapper {
  margin-left: -55px;
  background: $white;
  @media #{$lg,$md,$xs} {
    margin-left: 0px;
  }
}
.tp-header-logo-two-inner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 35px;
  display: flex;
  align-items: center;
  min-height: 150px;
  @media #{$xxl} {
    padding-left: 20px;
  }
  @media #{$xl,$xxl} {
    padding-left: 15px;
  }
  @media #{$lg,$md,$xs} {
    min-height: inherit;
    padding: 10px 15px;
  }
}
.tp-header-top-two {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 14px 20px;
  border-bottom: 1px solid #e5e8e9;
  @media #{$lg,$md,$xs} {
    display: none;
  }
  &-info {
    & ul {
      & li,
      & li a {
        font-size: 16px;
        font-weight: 400;
        color: $body-text-color-grey;
        & i {
          font-size: 30px;
          color: $theme-color;
          margin-right: 10px;
        }
      }
      & li {
        display: inline-flex;
        align-items: center;
        list-style: none;
        padding-right: 25px;
        margin-right: 25px;
        border-right: 2px dashed #e5e8e9;
        &:last-child {
          padding-right: 0px;
          margin-right: 0px;
          border-right: 0px;
        }
      }
    }
  }
  &-social {
    & a {
      height: 42px;
      width: 42px;
      line-height: 42px;
      text-align: center;
      font-size: 15px;
      color: $heading-color-black;
      background: $white;
      border-radius: 50%;
      box-shadow: 0px 0px 30px 0px rgba(46, 57, 88, 0.14);
      display: inline-block;
      margin-right: 8px;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        color: $white;
        background: $theme-color;
      }
    }
  }
}
.tp-header-menu-two {
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media #{$lg,$md,$xs} {
    padding: 0px;
  }
}
.tp-main-menu-two {
  & ul {
    & li {
      margin-left: 0px;
      margin-right: 25px;
      &:last-child {
        margin-right: 0px;
      }
      & a {
        font-size: 17px;
        font-weight: 500;
        color: $heading-color-black;
        &::before {
          display: none;
        }
        &:after {
          content: "\f067";
          margin-left: 5px;
          font-family: "Font Awesome 5 Pro";
          font-size: 14px;
          color: $heading-color-black;
        }
      }
      &:hover {
        & a:after {
          color: $theme-color;
        }
      }
      & .sub-menu a::before {
        display: inherit;
      }
    }
  }
}
.tp-main-menu-two-btn {
  display: inline-flex;
  @media #{$lg,$md,$xs} {
    display: none;
  }
  & a {
    height: 50px;
    line-height: 50px;
    padding: 0px 34px;
  }
}

// header home three start here
.tp-header-area-three {
  @media #{$lg,$md,$xs} {
    padding: 15px 0px;
  }
}
.tp-header-area-three.header-sticky.sticky-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  background: $white;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  @include transition(0.3s);
}
.tp-main-menu-three {
  padding-left: 40px;
  @media #{$xl} {
    padding-left: 0px;
  }
  & ul {
    & li {
      margin-left: 0px;
      margin-right: 35px;
      & a {
        color: $theme-blue;
        padding: 41px 0px;
        &::before {
          bottom: 40px;
        }
      }
    }
  }
}
.tp-header-right-three {
  display: flex;
  justify-content: end;
  align-items: center;
  & .tp-header-number-three {
    margin-right: 60px;
    @media #{$xxl} {
      margin-right: 40px;
    }
    @media #{$xl} {
      display: none;
    }
    & span {
      font-weight: 500;
      font-size: 18px;
      color: $theme-color;
      line-height: 1;
      & a {
        font-weight: 500;
        font-size: 20px;
        color: $heading-color-black;
      }
    }
  }
  & .tp-header-btn-three {
    transform: translateY(4px);
    & a {
      color: $theme-blue;
      &:hover {
        color: $white;
      }
    }
  }
}

//inner page header start here
.tp-main-menu-inner {
  & ul {
    & li {
      & a {
        color: $heading-color-black;
      }
      &:hover {
        & a {
          color: $theme-color;
        }
      }
    }
  }
}

// sidebar start
.offcanvas-end {
  width: 300px;
}
.offcanvas {
  background: #152136;
}
.offcanvas-header {
  margin-bottom: 15px;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
}
.offcanvas-body {
  padding: 30px;
}
.btn-close {
  background: url(../img/icon/close.png) center/1em auto no-repeat;
  opacity: 1;
  position: absolute;
  top: 30px;
  font-size: 25px;
}
.side-info {
  width: auto;
  height: 100%;
  position: static;
  z-index: 9999;
  right: inherit;
  top: 0;
  padding: 0px;
  transition: 0.6s;
  overflow-y: inherit;
}

.sidebar_sub_menu {
  list-style: none;
}
.sidebar_sub_menu li {
  margin-bottom: 10px;
  border-bottom: 1px solid #2a3a57;
  padding-bottom: 5px;
}
.sidebar_sub_menu li a {
  opacity: 0.75;
  padding-left: 25px;
}

.iconAdd {
  position: relative;
  color: white;
  border-top: 1px solid #2a3a57;
  border-bottom: 1px solid #2a3a57;
  padding: 5px 0;
  margin-bottom: 10px;
}
.iconAdd::before {
  content: "+";
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #2a3a57;
  border-left: 1px solid #2a3a57;
  padding: 17px;
}
.icon_close {
  position: relative;
  color: white;
  border-top: 1px solid #2a3a57;
  border-bottom: 1px solid #2a3a57;
  padding: 5px 0;
  /* margin-bottom: 10px; */
}
.icon_close::before {
  content: "-";
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: white;
  padding: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #2a3a57;
  border-left: 1px solid #2a3a57;
}

@media (max-width: 1200px) {
  nav#tp-mobile-menu {
    display: none;
  }
}
