/*----------------------------------------*/
/*  07. Choose
/*----------------------------------------*/
.common-yellow-shape.common-white-shape {
  &:before {
    background: $white;
  }
}
.tp-choose-timeline-single-title {
  font-size: 24px;
  line-height: 1.33;
  transform: translateY(-3px);
  @media #{$xl,$sm} {
    font-size: 22px;
    & br {
      display: none;
    }
  }
}
.tp-choose-timeline {
  border-left: 2px solid $theme-color;
}
.tp-choose-timeline-single {
  position: relative;
  @media #{$sm} {
    padding-left: 25px;
  }
  &:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: $theme-color;
    border: 4px solid #f9e89a;
    left: -12.5px;
    top: 0px;
    z-index: 1;
  }
  &:hover {
    &::before {
      animation: pulse-green 6s infinite;
    }
  }
}
.tp-choose-img {
  margin-left: -30px;
  @media #{$lg,$md,$xs} {
    margin-left: 0px;
  }
  & img {
    border: 10px solid $grey-bg;
    max-width: inherit;
    @media #{$xxl,$xl,$lg,$md,$xs} {
      max-width: 100%;
    }
  }
}
.tp-choose-yellow-bg {
  height: 590px;
  position: absolute;
  top: -255px;
  left: 0px;
  right: 0px;
  background: $theme-yellow;
  width: 100%;
  z-index: 1;
}
.tp-section-title-wrapper.mb-105 {
  @media #{$lg,$md,$xs} {
    margin-bottom: 60px;
  }
}

// choose home three start here
.tp-choose-three {
  & p {
    color: #6a6b71;
  }
  & .tp-about-service-text-title {
    color: $theme-blue;
    &:hover {
      color: $theme-color;
    }
  }
  & .tp-about-service-icon {
    & i {
      color: $theme-blue;
    }
  }
  & .yellow-circle-shape::before {
    background: #e3e6f3;
  }
}
.tp-choose-area-three-img {
  position: absolute;
  top: 0px;
  left: 0px;
  @media #{$xxxl} {
    & img {
      max-width: 770px;
    }
  }
  @media #{$xxl} {
    & img {
      max-width: 670px;
    }
  }
  @media #{$xl} {
    & img {
      max-width: 570px;
    }
  }
  @media #{$lg,$md,$xs} {
    position: relative;
    margin-bottom: 40px;
    & img {
      width: 100%;
    }
  }
}
.tp-choose-three-year-inner {
  display: inline-block;
  text-align: left;
  padding-left: 60px;
  @media #{$xs} {
    padding-left: 20px;
  }
  & h3 {
    font-size: 60px;
    line-height: 1;
    color: $white;
    margin-bottom: 5px;
    font-family: $roboto;
    font-weight: 700;
    @media #{$xs} {
      font-size: 42px;
    }
  }
  & h4 {
    font-size: 45px;
    color: $white;
    line-height: 1.3;
    margin-bottom: 0px;
    font-family: $roboto;
    font-weight: 700;
    @media #{$xs} {
      font-size: 32px;
    }
  }
}
.tp-choose-three-year {
  background: $theme-color;
  height: 340px;
  width: 480px;
  border: 20px solid $white;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-right: 52px;
  @media #{$xs} {
    border: none;
    height: 200px;
    width: 280px;
  }
}
.tp-choose-three-year-responsive {
  display: none;
  @media #{$lg,$md,$xs} {
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}
.tp-choose-area-three {
  @media #{$lg,$md,$xs} {
    padding-bottom: 45px;
  }
}
