// Font Family
$nunitosans: "Nunito Sans", sans-serif;
$roboto: "Roboto", sans-serif;

// Colors ---------------
$white: #ffffff;
$theme-color: #a70551;
$theme-dark-color: #a70551;
$theme-yellow: #fed10c;
$theme-yellow-dark: #ffc000;
$theme-blue: #102579;
$theme-blue-dark: #051868;
$body-text-color: #474546;
$body-text-color-grey: #626664;
$body-text-color-grey3: #66686b;
$grey-bg: #ecf3f0;
$grey-bg-light: #f7f7f7;
$grey-bg-lighttwo: #f5f5f5;

// Heading Color
$heading-color: #a70551;
$heading-color-black: #09150f;

// Soft color

// Responsive Variables
$xxxl: "only screen and (min-width: 1600px) and (max-width: 1750px)";
$xxl: "only screen and (min-width: 1400px) and (max-width: 1599px)";
$xl: "only screen and (min-width: 1200px) and (max-width: 1399px)";
$lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs: "(max-width: 767px)";
$sm: "only screen and (min-width: 576px) and (max-width: 767px)";
