/*----------------------------------------*/
/*  14. Footer
/*----------------------------------------*/
.tp-footer-area.pb-70 {
  @media #{$sm} {
    padding-bottom: 60px;
  }
}
.tp-footer-widget-title {
  font-size: 24px;
  color: $white;
  position: relative;
  padding-bottom: 10px;
  &:before {
    position: absolute;
    content: "";
    height: 2px;
    width: 60px;
    background: $theme-yellow;
    left: 0px;
    bottom: 0px;
  }
}
.tp-footer-info {
  & h4 {
    font-size: 24px;
    font-weight: 500;
    color: $white;
    font-family: $roboto;
  }
  & h6 {
    font-size: 16px;
    font-weight: 400;
    color: $white;
    font-family: $roboto;
    line-height: 1.6;
    display: inline-flex;
    & i {
      font-size: 16px;
      color: $white;
      min-width: 25px;
      transform: translateY(4px);
    }
  }
  &-social {
    & a {
      height: 40px;
      width: 40px;
      display: inline-block;
      line-height: 40px;
      border-radius: 50%;
      font-size: 16px;
      color: #6e2145;
      background: #fed10c;
      margin-right: 10px;
      text-align: center;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        color: $white;
        background: #bc9900;
      }
    }
  }
}
.footer-logo {
  width: 250px;
}
.tp-footer-widget {
  & ul {
    & li {
      list-style: none;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
      & a {
        font-size: 16px;
        position: relative;
        color: $white;
        &:hover {
          padding-left: 5px;
          color: $theme-yellow;
        }
        &:before {
          content: "\f054";
          font-size: 12px;
          display: inline-block;
          margin-right: 10px;
          font-family: "Font Awesome 5 Pro";
          transform: translateY(-2px);
        }
      }
    }
  }
}
.tp-footer-news-single {
  & h6 {
    font-size: 16px;
    font-weight: 400;
    font-family: $roboto;
    color: $white;
    line-height: 27px;
    margin-bottom: 5px;
    @media #{$lg,$sm} {
      line-height: 19px;
    }
    &:hover {
      color: $theme-yellow;
    }
  }
  & span {
    display: block;
    color: $white;
    font-size: 16px;
  }
}
.tp-footer-insta {
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: wrap;
  & a {
    width: 33.33%;
    padding: 0px 5px;
    margin-bottom: 10px;
    display: block;
    & img {
      width: 100%;
    }
  }
}
.footer-p-about {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}
.footer-col-2 {
  padding-left: 20px;
  @media #{$lg} {
    padding-left: 30px;
  }
  @media #{$md,$xs} {
    padding-left: 0px;
  }
}
.footer-col-3 {
  padding-left: 30px;
  @media #{$lg} {
    padding-left: 5px;
  }
  @media #{$md,$xs} {
    padding-left: 0px;
  }
}
.footer-col-4 {
  padding-left: 30px;
  @media #{$lg} {
    padding-left: 10px;
  }
  @media #{$md,$xs} {
    padding-left: 0px;
  }
}

.tp-footer-subscribe-title {
  font-size: 25px;
  @media #{$xs} {
    font-size: 23px;
  }
  @media #{$lg} {
    font-size: 25px;
  }
}
.tp-footer-subscribe-form {
  & form {
    display: flex;
    justify-content: end;
    padding-right: 80px;
    @media #{$xl} {
      padding-right: 50px;
    }
    @media #{$lg} {
      padding-right: 30px;
    }
    @media #{$md,$xs} {
      justify-content: start;
    }
    @media #{$xs} {
      padding-right: 0px;
      display: inherit;
    }
    @media #{$sm} {
      display: flex;
    }
    & .tp-footer-subscribe-form-field {
      position: relative;
      margin-right: 10px;
      @media #{$xs} {
        margin-right: 0px;
      }
      @media #{$sm} {
        margin-right: 10px;
      }
      & input {
        height: 60px;
        width: 420px;
        display: block;
        padding: 0px 45px 0px 25px;
        font-size: 15px;
        color: $body-text-color;
        border: none;
        background: $white;
        outline: none;
        @media #{$xl,$lg} {
          width: 350px;
        }
        @media #{$xs} {
          width: 100%;
          padding-left: 15px;
        }
        @media #{$sm} {
          width: 330px;
          padding-left: 25px;
        }
      }
      & i {
        font-size: 16px;
        color: $body-text-color;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
      }
    }
    & .tp-footer-subscribe-form-btn {
      & button {
        background: $body-text-color;
        &:hover {
          background: $theme-color;
          color: $white;
        }
      }
    }
  }
}
.tp-footer-subscribe-area {
  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 600px;
    background: $theme-yellow;
    top: 0px;
    left: 0px;
    z-index: 1;
    @media #{$xs} {
      width: 100%;
    }
  }
}
.tp-footer-subscribe-shape {
  position: absolute;
  height: 60px;
  background: #084d2b;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}
.tp-copyright {
  & p {
    font-size: 16px;
    line-height: 1.3;
    color: $white;
    @media #{$xs} {
      font-size: 16px;
    }
    & span {
      color: $theme-yellow-dark;
    }
  }
}
.tp-footer-subscribe-bg {
  @media #{$md,$xs} {
    padding-top: 20px;
  }
}
.tp-copyright-area {
  @media #{$xs} {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

//footer home two start here

.tp-footer-subscribe-bg-two {
  @media #{$md,$xs} {
    padding: 20px 30px 20px 30px;
  }
}
.tp-footer-subscribe-form form .tp-footer-subscribe-form-field input {
  @media #{$sm} {
    width: 270px;
  }
}
.tp-copyright-area-two {
  @media #{$xs} {
    padding: 20px 0px;
  }
}

//footer home three start here
.tp-footer-info-three {
  & p {
    padding-right: 21px;
    @media #{$xs} {
      padding-right: 0px;
    }
  }
}
.tp-footer-info-social-three {
  & a {
    border-radius: 0px;
    background: #36437a;
    &:hover {
      background: $theme-color;
    }
  }
}
.footer-col-3-3 {
  padding-left: 15px;
}
.tp-footer-news-three-single {
  &-img {
    width: 80px;
    float: left;
    margin-right: 20px;
    @media #{$lg,$xs} {
      margin-right: 10px;
    }
  }
  &-text {
    overflow: hidden;
    & p {
      color: #fefefe;
    }
  }
}
.tp-footer-newsletter-three {
  & p {
    color: $white;
    font-size: 16px;
    line-height: 1;
    @media #{$xl,$lg,$md,$xs} {
      line-height: 1.3;
    }
  }
  & form {
    & input {
      height: 60px;
      width: 100%;
      background: $white;
      border-radius: 4px;
      padding: 0px 40px 0px 20px;
      color: #6a6b71;
      font-size: 15px;
      border: 0px;
      outline: none;
    }
    & .tp-footer-newsletter-three-field {
      & i {
        position: absolute;
        top: 23px;
        font-size: 15px;
        color: $theme-color;
        right: 20px;
      }
    }
    & button {
      color: $theme-blue;
      border-radius: 50px;
      &:hover {
        color: $white;
      }
    }
  }
}
